.table thead tr th {
  border-bottom: 1px solid #e7ecf1 !important;
  font-weight: 600;
  color: #009ef7;
}

.table> :not(:first-child) {
  border-top: 0 !important;
}

.col-form-label {
  font-weight: 400 !important;
}

.tableFixHead {
  overflow: auto;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background: #fff;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: transparent !important;
}

.svg-icon.svg-icon-lg svg {
  height: 2.75rem !important;
  width: 2.75rem !important;
}

.form-select {
  font-weight: 400 !important;
}

.marker-label {
  color: black;
  background-color: white;
  font-size: 14px;
  padding: 6px;
  margin-bottom: 100px;
}

.table-responsive-sm {
  height: 435px;
  overflow: auto;
}